import style from './styleButtons.module.css'


const CustomButton = ({ children,
    blueBtn,
    maroonBtn,
    greenBtn,
    yellowBtn, ...otherProps }) => {

    return (<button
        className={`
        btn            
        btn-sm-block
        btn-xs-block
        ${blueBtn ? style.blueBtn : ''}
        ${maroonBtn ? style.maroonBtn : ''}        
        ${yellowBtn ? style.yellowBtn : ''}
        ${greenBtn ? style.greenBtn : ''}
        `}
        {...otherProps}
    >
        {children}
    </button>
    )
}
export default CustomButton;